var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('md-card',{staticClass:"dash-content"},[_c('div',{staticClass:"header-wrapper"},[_c('PageHeader',{attrs:{"title":"Manage Professionals Services"}}),_c('div',{staticClass:"loader"},[(_vm.loading || _vm.updating || _vm.deleting)?_c('Wave',{attrs:{"width":"50px","color":"#d9534f"}}):_vm._e()],1),_c('div',[_c('md-button',{staticClass:"md-primary",attrs:{"to":{ name: 'Create Services' }}},[_vm._v(" Add New Service ")])],1)],1),_c('md-table',{attrs:{"md-card":""},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"S/N"}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-label":"Title"}},[_vm._v(_vm._s(item.title))]),_c('md-table-cell',{attrs:{"md-label":"Slug"}},[_vm._v(_vm._s(item.slug))]),_c('md-table-cell',{attrs:{"md-label":"Featured"}},[_c('div',{staticClass:"badge",class:{
                success: item.featured == 1,
                bggreen: item.featured == 0,
              }},[_vm._v(" "+_vm._s(item.featured == 1 ? "Yes" : "No")+" ")])]),_c('md-table-cell',{attrs:{"md-label":"Visibility"}},[_c('div',{staticClass:"badge",class:{
                success: item.status == 'live',
                default: item.status == 'draft',
              }},[_vm._v(" "+_vm._s(item.status)+" ")])]),_c('md-table-cell',{attrs:{"md-label":"Actions"}},[_c('md-menu',{attrs:{"md-size":"medium","md-align-trigger":""}},[_c('md-button',{staticClass:"bggreen",attrs:{"disabled":_vm.updating,"md-menu-trigger":""}},[_vm._v("Menu")]),_c('md-menu-content',[_c('router-link',{attrs:{"tag":"md-menu-item","to":{ name: 'Edit Services', params: { id: item.id } }}},[_vm._v(" Edit ")]),_c('md-menu-item',{on:{"click":function($event){return _vm.remove(item.id)}}},[_vm._v("Delete ")])],1)],1)],1)],1)}}]),model:{value:(_vm.services.mdData),callback:function ($$v) {_vm.$set(_vm.services, "mdData", $$v)},expression:"services.mdData"}}),(_vm.loading)?_c('span',{staticClass:"b-loading"},[_vm._v("Loading...")]):_vm._e(),_c('pagination',{attrs:{"records":_vm.services.mdCount,"per-page":_vm.services.mdPerPage},on:{"paginate":_vm.updatePagination},model:{value:(_vm.services.mdPage),callback:function ($$v) {_vm.$set(_vm.services, "mdPage", $$v)},expression:"services.mdPage"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
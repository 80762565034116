<template>
  <div>
    <div class="container">
      <md-card class="dash-content">
        <div class="header-wrapper">
          <PageHeader title="Manage Professionals Services" />
          <div class="loader">
            <Wave
              v-if="loading || updating || deleting"
              width="50px"
              color="#d9534f"
            />
          </div>
          <div>
            <md-button class="md-primary" :to="{ name: 'Create Services' }">
              Add New Service
            </md-button>
          </div>
        </div>
        <md-table v-model="services.mdData" md-card>
          <md-table-row slot-scope="{ item }" slot="md-table-row">
            <md-table-cell md-label="S/N">{{ item.id }}</md-table-cell>
            <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
            <md-table-cell md-label="Slug">{{ item.slug }}</md-table-cell>
            <md-table-cell md-label="Featured">
              <div
                class="badge"
                :class="{
                  success: item.featured == 1,
                  bggreen: item.featured == 0,
                }"
              >
                {{ item.featured == 1 ? "Yes" : "No" }}
              </div>
            </md-table-cell>
            <md-table-cell md-label="Visibility">
              <div
                class="badge"
                :class="{
                  success: item.status == 'live',
                  default: item.status == 'draft',
                }"
              >
                {{ item.status }}
              </div></md-table-cell
            >
            <md-table-cell md-label="Actions">
              <md-menu md-size="medium" md-align-trigger>
                <md-button :disabled="updating" md-menu-trigger class="bggreen"
                  >Menu</md-button
                >

                <md-menu-content>
                  <router-link
                    tag="md-menu-item"
                    :to="{ name: 'Edit Services', params: { id: item.id } }"
                  >
                    Edit
                  </router-link>
                  <md-menu-item @click="remove(item.id)">Delete </md-menu-item>
                </md-menu-content>
              </md-menu>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <span v-if="loading" class="b-loading">Loading...</span>
        <pagination
          v-model="services.mdPage"
          :records="services.mdCount"
          :per-page="services.mdPerPage"
          @paginate="updatePagination"
        />
      </md-card>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("professional");
export default {
  name: "professionalServices",
  data() {
    return {
      updating: false,
      deleting: false,
    };
  },

  methods: {
    ...mapActions(["getProfessionalServices", "deleteService"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getProfessionalServices(data);
    },
    async remove(id) {
      try {
        const conf = confirm("Are you sure?");
        if (conf) {
          this.deleting = true;
          await this.deleteService(id);
          this.deleting = false;
          this.toast("success", "Service Deleted");
        }
      } catch (err) {
        this.deleting = false;

        this.toast("error", "Opps, something went wrong");
      }
    },
  },
  mounted() {
    this.getProfessionalServices();
  },
  computed: {
    ...mapGetters(["services", "loading"]),
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: green;
  }

  &.danger {
    background: red;
  }
}
</style>
